import React from 'react';
import { Typography } from '@material-ui/core';
import './Job.css'
import Footer from '../../Footer/Footer';


 const Job = () => {
    return (
        <div >
            <div className='jobBody'>
        <div className='jobHeading'>
            <Typography className='pageTitle' variant='h3'>Join The Beauty Secrets Team</Typography>
            <img src="../images/therapists.jpg" alt="image" />
           <Typography className='joinUs' variant='h5'>  JOIN OUR TEAM TODAY AND WORK IN A FUN AND EXCITING PLACE</Typography>
             <Typography variant='h6' className='jobText'>The experience working here is as amazing and exciting as you want to look. this state of the art Spa is positioned at the ever calm Oka Okoko Crescent , Off Lagos street Garki, Abuja</Typography>
             <Typography  variant='h5'
             className='jobLink'> 
            <a href='https://store.beautysecretskincarespa.com/jobs/'>CHECK HERE FOR AVAILABLE POSITIONS</a> 
             
             </Typography>

        </div>
        </div>
        <Footer />
        </div>
    )
}
export default Job;